import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

const DEFAULT_DATE_TIME_FORMAT: string = 'DD.M.yyyy, HH:mm';

/**
 * Приводит время к локальному значению по передаваемому часовому поясу
 */
@Pipe({
  name: 'transformToLocalTime',
})
export class transformToLocalTimePipe implements PipeTransform {
  public transform(
    date: string,
    time_zone: string = 'Europe/Moscow',
    format: string = DEFAULT_DATE_TIME_FORMAT,
  ): string {
    return moment(date).tz(time_zone).format(format);
  }
}
