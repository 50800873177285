import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeDate',
})
export class RelativeDatePipe implements PipeTransform {
  transform(date: string | Date, format: string = 'dd.MM.yyyy') {
    const processingDate: Date = new Date(date);
    const today: Date = new Date();
    const yesterday: Date = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (
      processingDate.getFullYear() == today.getFullYear() &&
      processingDate.getMonth() == today.getMonth() &&
      processingDate.getDate() == today.getDate()
    )
      return 'Сегодня';
    else if (
      processingDate.getFullYear() == yesterday.getFullYear() &&
      processingDate.getMonth() == yesterday.getMonth() &&
      processingDate.getDate() == yesterday.getDate()
    )
      return 'Вчера';
    else {
      return new DatePipe('ru-RU').transform(processingDate, format);
    }
  }
}
