import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeyValue } from '@shared/models/key-value.model';
import { IChatMessageDto, IRecipientInfo } from '@shared/models/messages/dto/chat-message-dto.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import {
  IChatMessagesPositions,
  IChatUnreadMessagesCount,
  ShortMessageWithReaction,
} from '@shared/models/messages/view/chat-message.model';
import { QueryParams } from '@shared/models/query-params.model';
import { prepareQuery } from '@shared/utils/utils';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChatMessageHttpService {
  constructor(private http: HttpService) {}

  getChatMessages(chatId: number, params: QueryParams): Observable<ArrayPayload<IChatMessageDto>> {
    return this.http.get<ArrayPayload<IChatMessageDto>>(this._getBaseMessagesApi(chatId), {
      params: prepareQuery(params),
    });
  }

  getChatMessageById(chatId: number, messageId: number, params?: KeyValue<string>): Observable<IChatMessageDto> {
    return this.http.get<IChatMessageDto>(`${this._getBaseMessagesApi(chatId)}${messageId}/`, {
      params: params && new HttpParams({ fromObject: params }),
    });
  }

  createChatMessage(chatId: number, chatMessage: Partial<IChatMessageDto>): Observable<IChatMessageDto> {
    return this.http.post<IChatMessageDto>(this._getBaseMessagesApi(chatId), chatMessage);
  }

  public getChatMessageParticipantsInfo(
    chatId: number,
    messageId: number,
    params: QueryParams,
  ): Observable<ArrayPayload<IRecipientInfo>> {
    return this.http.get<ArrayPayload<IRecipientInfo>>(
      `${this._getBaseMessagesApi(chatId)}${messageId}/recipients-info/`,
      { params: prepareQuery(params) },
    );
  }

  updateChatMessageById(
    chatId: number,
    messageId: number,
    chatMessage: Partial<IChatMessageDto> | FormData,
    params?: KeyValue<string>,
  ): Observable<IChatMessageDto> {
    return this.http.put<IChatMessageDto>(`${this._getBaseMessagesApi(chatId)}${messageId}/`, chatMessage, {
      params: params && new HttpParams({ fromObject: params }),
    });
  }

  patchChatMessageById(
    chatId: number,
    messageId: number,
    chatMessage: Partial<IChatMessageDto> | FormData,
    params?: KeyValue<string>,
  ): Observable<IChatMessageDto> {
    return this.http.patch<IChatMessageDto>(`${this._getBaseMessagesApi(chatId)}${messageId}/`, chatMessage, {
      params: params && new HttpParams({ fromObject: params }),
    });
  }

  deleteChatMessageById(chatId: number, messageIds: number[] | number, isDeleteForAll?: boolean): Observable<void> {
    return this.http.post<void>(`/chat/chat/${chatId}/messages/delete-messages/`, {
      messages: typeof messageIds === 'number' ? [messageIds] : messageIds,
      for_all: !!isDeleteForAll,
    });
  }

  // deleteChatMessageById(chatId: number, messageId: number, params?: KeyValue<string>): Observable<void> {
  //   return this.http.post<void>(`${this._getBaseMessagesApi(chatId)}${messageId}/`, {
  //     params: params && new HttpParams({ fromObject: params }),
  //   });
  // }

  // Позиция сообщения
  getMessagesPosition(
    chatId: number,
    params?: KeyValue<string>,
    paramsString?: string,
  ): Observable<IChatMessagesPositions> {
    return this.http.get<IChatMessagesPositions>(`/chat/chat/${chatId}/messages/positions/`, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  // Прочитать все сообщения до отправленного
  readChatMessagesUntilMessageId(chatId: number, messageId: number): Observable<number> {
    const msgObj = { message: messageId };

    return this.http
      .post<IChatUnreadMessagesCount>(`/chat/chat/${chatId}/messages/read-until/`, msgObj)
      .pipe(map((unreadLeftCount: IChatUnreadMessagesCount) => unreadLeftCount.unread_msgs_left_count));
  }

  private _getBaseMessagesApi(chatId: number): string {
    return `/chat/chat/${chatId}/messages/`;
  }

  // Запросы на реакции сообщения в чатах
  postChatMessageReaction(
    chatId: number,
    chatMessageId: number,
    msgReaction: { reaction: string },
  ): Observable<IChatMessageDto> {
    return this.http.post<IChatMessageDto>(`/chat/chat/${chatId}/messages/${chatMessageId}/reactions/my/`, msgReaction);
  }

  deleteChatMessageReaction(chatId: number, chatMessageId: number): Observable<IChatMessageDto> {
    return this.http.delete<IChatMessageDto>(`/chat/chat/${chatId}/messages/${chatMessageId}/reactions/my/`);
  }

  // Пометить реакцию прочитанной
  markReactionAsRead(chatId: number, chatMessageIds: number[]): Observable<IChatMessageDto> {
    const params = { messages: chatMessageIds };
    return this.http.post<IChatMessageDto>(`/chat/chat/${chatId}/messages/reactions/mark-seen/`, params);
  }

  // Получить список всех непрочитанных реакций
  getChatMessagesUnseenReactions(chatId: number): Observable<ArrayPayload<ShortMessageWithReaction>> {
    return this.http.get<ArrayPayload<ShortMessageWithReaction>>(
      `/chat/chat/${chatId}/messages/reactions/unseen-reactions/`,
    );
  }

  public getChatMessagesUnseenMentions(chatId: number): Observable<ArrayPayload<ShortMessageWithReaction>> {
    return this.http.get<ArrayPayload<ShortMessageWithReaction>>(
      `/chat/chat/${chatId}/messages/unseen-marks-messages/`,
    );
  }
}
