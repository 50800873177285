import { NotificationTypeKey } from '@modules/notifications/enums/keys.enum';
import { SEX_KEY_TO_VIEW_NAME_SHORT } from '@modules/profile/constants/keys-to-view-names.const';
import {
  DATE_DAY_NUMBER_MONTH,
  DATE_FULL,
  DATE_FULL_BACKEND,
  DATE_FULL_MONTH_WORD,
  DATE_FULL_WITH_MONTH_WORD_AND_TIME,
  DATE_SHORT,
  DATE_WITH_TIME_NO_MS_BACKEND,
  DAY_NUMBER_AND_MONTH_WORD,
  MASK_HOUR_MINUTE,
  OPTIONS_SOURCES,
  QUILL_MODULES_DEAFULT_SETTINGS,
} from '@shared/constants/common.const';
import { FilterValueInputType } from '@shared/enums/filter/filter-value-input-type.enum';
import { FilterType } from '@shared/enums/filter/filters-type.enum';
import { SortType } from '@shared/enums/filter/sort-type.enum';
import { DatePickerMode, ElementPostionKey } from '@shared/enums/keys.enum';
import { SelectBarViewMode } from '@shared/enums/select/select-bar-view-mode.enum';
import { SelectMode } from '@shared/enums/select/select-mode.enum';
import { SelectUpdatorField } from '@shared/enums/select/select-updator-field.enum';
import { SelectViewMode } from '@shared/enums/select/select-view-mode.enum';
import { ViewContent } from '@shared/enums/view-content.enum';
import { KeyValue } from '@shared/models/key-value.model';
import { SubSink } from 'subsink';

export abstract class BaseClass {
  readonly subs = new SubSink();
  readonly dialogRefSubs = new SubSink();

  readonly sortType = SortType;
  readonly elementPositionKey = ElementPostionKey;
  readonly selectMode = SelectMode;
  readonly selectViewMode = SelectViewMode;
  readonly selectBarViewMode = SelectBarViewMode;
  readonly selectUpdatorField = SelectUpdatorField;
  readonly filterType = FilterType;
  readonly dataViewContent = ViewContent;
  readonly filterValueInputType = FilterValueInputType;
  readonly datePickerMode = DatePickerMode;
  readonly notificationTypesKeysList = Object.values(NotificationTypeKey);

  readonly sexKeyToViewNameShort = SEX_KEY_TO_VIEW_NAME_SHORT as KeyValue<string>;

  readonly dateWithTimeNoMsBackend = DATE_WITH_TIME_NO_MS_BACKEND;
  readonly dateShort = DATE_SHORT;
  readonly dateFull = DATE_FULL;
  readonly dateFullMonthWord = DATE_FULL_MONTH_WORD;
  readonly dayNumberAndMonthWord = DAY_NUMBER_AND_MONTH_WORD;
  readonly dateDayNumberMonth = DATE_DAY_NUMBER_MONTH;
  readonly dateFullWithMonthWordAndTime = DATE_FULL_WITH_MONTH_WORD_AND_TIME;
  readonly dateFullBackend = DATE_FULL_BACKEND;
  readonly maskHourMinute = MASK_HOUR_MINUTE;

  readonly quillModulesDefaultSettings = QUILL_MODULES_DEAFULT_SETTINGS;

  readonly optionsSources = OPTIONS_SOURCES;
}
