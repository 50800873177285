import { Pipe, PipeTransform } from '@angular/core';
import { DomService } from '@core/services/business/utils/dom.service';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private readonly domService: DomService) {}

  public transform(html: string): SafeHtml {
    return this.domService.bypassSecurityTrustHtml(html);
  }
}
