import { Injectable, SimpleChanges } from '@angular/core';
import { SelectComponent } from '@shared/components/select/select.component';
import { BASE_OPTIONS_SOURCE, OPTIONS_SOURCES } from '@shared/constants/common.const';
import { SelectMode } from '@shared/enums/select/select-mode.enum';
import { SelectOptionActionType } from '@shared/enums/select/select-option-action-type.enum';
import { SelectUpdatorField } from '@shared/enums/select/select-updator-field.enum';
import { SelectViewMode } from '@shared/enums/select/select-view-mode.enum';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { IOptionsSources, ISelectOption } from '@shared/models/select.model';
import * as _ from 'lodash';
import { MembersBusinessTurnoverService } from '../members/members-business-turnover.service';
import { MembersIndustryService } from '../members/members-industry.service';
import { DomService } from './dom.service';
import { UtilsService } from './utils.service';
import { MembersUserService } from '../members/members-user.service';
import { UserRelatedFieldKey } from '@shared/enums/keys.enum';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SelectService {
  optionsSources: IOptionsSources;

  constructor(
    private domService: DomService,
    private utilsService: UtilsService,
    private membersBusinessTurnoverService: MembersBusinessTurnoverService,
    private membersIndustryService: MembersIndustryService,
    private memberUserService: MembersUserService,
  ) {}

  initOptionsSourcesConstants() {
    OPTIONS_SOURCES.businessesTurnovers = {
      listItemsRequest: (filters: KeyValue<string>) =>
        this.membersBusinessTurnoverService.getBusinessesTurnoversToBusinessesTurnovers(null, filters),
      singleItemRequest: (id: number) => this.membersBusinessTurnoverService.getById(id),
    };

    OPTIONS_SOURCES.industries = {
      listItemsRequest: (filters: KeyValue<string>) =>
        this.membersIndustryService.getIndustriesAndAddToIndustries(null, filters),
      singleItemRequest: (id: number) => this.membersIndustryService.getById(id),
    };

    OPTIONS_SOURCES.staffMembers = {
      listItemsRequest: (filters: KeyValue<string>) =>
        this.memberUserService.getStaffUsers(null, filters).pipe(map((response) => response.results)),
      singleItemRequest: (id: number) => this.memberUserService.getUserById(id),
      viewNameForTransformToSelects: UserRelatedFieldKey.FirstAndLastName,
    };

    OPTIONS_SOURCES.users = {
      listItemsRequest: (filters: KeyValue<string>) =>
        this.memberUserService.getUsersAndAddToUsers(null, filters).pipe(map((response) => response.results)),
      singleItemRequest: (id: number) => this.memberUserService.getUserById(id),
      viewNameForTransformToSelects: UserRelatedFieldKey.FirstAndLastName,
    };

    this.optionsSources = OPTIONS_SOURCES;
  }

  onChanges(context: SelectComponent, changes: SimpleChanges) {
    const isOptionsUpdated = !!changes.options?.currentValue;
    const isSelectedOptionUpdated = !!changes.selectedOption?.currentValue && this.isSingleSelectModeEnabled(context);
    const isSelectedOptionsUpdated = !!changes.selectedOptions?.currentValue && this.isMultiSelectModeEnabled(context);

    if (changes.control?.currentValue) {
      context.controlChangesHandler();
    }

    if (changes.optionsSource?.currentValue) {
      this.resetOptions(context);
      context.optionsSource = { ...BASE_OPTIONS_SOURCE, ...changes.optionsSource.currentValue };
      this.getOptionsFromSource(context);
    }

    if (isOptionsUpdated) {
      this.resetOptions(context);
      this.setOptionsContainerHeight(context);
      this.initNewOptions(context, changes.options.currentValue);
    }

    if (isSelectedOptionUpdated) {
      context.selectedOption = _.cloneDeep(changes.selectedOption.currentValue);
    } else if (isSelectedOptionsUpdated) {
      context.selectedOptions = _.cloneDeep(changes.selectedOptions.currentValue);
    }

    if (!context.optionsSource) {
      this.updateOptionsState(context);
    }
  }

  initNewOptions(context: SelectComponent, options: ISelectOption[]) {
    options = _.cloneDeep(options);

    options?.forEach((option) => {
      option.id = this.utilsService.getUniqueId();
    });

    if (this.isSingleSelectModeEnabled(context) && !context.selectedOption) {
      context.selectedOption = options?.find((option) => option.isSelected);
    } else if (this.isMultiSelectModeEnabled(context) && !context.selectedOptions) {
      context.selectedOptions = options?.filter((option) => option.isSelected);
    }

    context.options = context.options || [];
    context.options.push(...options);
  }

  updateOptionsState(context: SelectComponent) {
    this.deselectAllOptions(context);

    if (this.isSingleSelectModeEnabled(context)) {
      if (context.selectedOptionValue !== undefined && context.selectedOptionValue !== null) {
        this.seletOptionByUpdatorKey(context, context.selectedOptionValue);
      } else if (context.selectedOption) {
        this.setSelectOptionByOption(context, context.selectedOption);
      }
    } else if (this.isMultiSelectModeEnabled(context)) {
      if (context.selectedOptionsValues) {
        this.seletOptionsByUpdatorKey(context, context.selectedOptionsValues);
      } else if (context.selectedOptions) {
        this.setSelectOptionsByOptions(context, context.selectedOptions);
      }
    }
  }

  setOptionsContainerHeight(context: SelectComponent) {
    if (
      !context.optionsSource ||
      !context.options.length ||
      context.options.length < context.optionsSource.limit ||
      (context.isAllOptionsLoaded && context.options.length <= context.optionsSource.limit)
    ) {
      context.optionsContainerHeightPx = null;
    } else {
      context.optionsContainerHeightPx = context.optionsSource.limit * context.optionMinHeightPx * 0.9;
    }
  }

  resetOptions(context: SelectComponent) {
    context.selectedOption = null;
    context.selectedOptions = [];
    context.options = [];
    context.isAllOptionsLoaded = false;
  }

  activateSelect(context: SelectComponent) {
    if (this.isDefaultViewMode) {
      context.isSelectActive = true;
    }
  }

  deactivateSelect(context: SelectComponent) {
    if (this.isDefaultViewMode(context)) {
      context.isSelectActive = false;
    }
  }

  selectOptionFromTemplate(context: SelectComponent, option: ISelectOption) {
    switch (context.mode) {
      case SelectMode.SingleSelect: {
        this.switchOptionAndUpdateSelectedOption(context, option);
        break;
      }
      case SelectMode.MultiSelect: {
        this.switchOptionAndUpdateSelectedOptions(context, option);
        break;
      }
      case SelectMode.Menu: {
        this.selectMenuOption(context, option);
        break;
      }
    }
  }

  switchOptionAndUpdateSelectedOption(context: SelectComponent, option: ISelectOption) {
    if (
      context.selectedOption &&
      this.isEqualOptions(context, context.selectedOption, option) &&
      context.canDeselectInSingleMode
    ) {
      option.isSelected = false;
      context.selectedOption = null;
      context.optionDeselectedEvent.emit(option);

      if (context.optionsUpdatorField) {
        context.valueDeselectedEvent.emit(option[context.optionsUpdatorField]);
      }
      context.control?.markAsDirty();
    } else if (!context.selectedOption || !this.isEqualOptions(context, context.selectedOption, option)) {
      this.deselectAllOptions(context);
      option.isSelected = true;
      context.selectedOption = option;
      context.selectedOptionChangeEvent.emit(context.selectedOption);

      if (context.optionsUpdatorField) {
        context.selectedOptionValue = context.selectedOption[context.optionsUpdatorField];
        context.selectedValueChangeEvent.emit(context.selectedOptionValue);
      }
      context.control?.markAsDirty();
    }
  }

  switchOptionAndUpdateSelectedOptions(context: SelectComponent, option: ISelectOption) {
    if (option.isRequired && option.isSelected) {
      return;
    }

    option.isSelected = !option.isSelected;
    context.selectedOptions = context.options.filter((o) => o.isSelected);
    context.selectedOptionsChangeEvent.emit(context.selectedOptions);

    if (context.optionsUpdatorField) {
      context.selectedOptionsValues = context.selectedOptions.map((o) => o[context.optionsUpdatorField]);
      context.selectedValuesChangeEvent.emit(context.selectedOptionsValues);
    }
    context.control?.markAsDirty();
  }

  selectMenuOption(context: SelectComponent, option: ISelectOption) {
    switch (option.actionType) {
      case SelectOptionActionType.Action: {
        context.selectedOptionChangeEvent.emit(option);
        break;
      }
      case SelectOptionActionType.FileInput: {
        context.options.forEach((o) => {
          o.waitForFileUpload = false;
        });
        option.waitForFileUpload = true;
        this.domService.getElementById(option.id).click();
        break;
      }
    }
    this.deactivateSelect(context);
  }

  deselectAllOptions(context: SelectComponent) {
    context.options?.forEach((i) => {
      i.isSelected = false;
    });
  }

  seletOptionByUpdatorKey(context: SelectComponent, selectedOptionValue: string) {
    const targetOption = context.options?.find(
      (option) => option[context.optionsUpdatorField].toString() === selectedOptionValue.toString(),
    );
    if (targetOption) {
      this.setSelectedOption(context, targetOption);
    }

    const isAlreadySelected =
      (context.selectedOption &&
        context.selectedOption[context.optionsUpdatorField].toString() === selectedOptionValue.toString()) ||
      (context.selectedOptions &&
        context.selectedOptions.find(
          (option) => option[context.optionsUpdatorField].toString() === selectedOptionValue.toString(),
        ));
    if (isAlreadySelected) {
      return;
    }

    if (context.optionsSource && !targetOption) {
      context.loadMissingOptionAndSet(selectedOptionValue);
    }
  }

  getOptionsFromSource(context: SelectComponent) {
    if (context.isOptionsLoading || context.isAllOptionsLoaded) {
      return;
    }
    context.isOptionsLoading = true;

    const filters: KeyValue<string> = {
      limit: context.optionsSource.limit.toString(),
      offset: context.options ? context.options?.length.toString() : '0',
    };

    context.loadOptionsAndSet(filters);
  }

  getOptionsFromSourceResponseActions(context: SelectComponent, response: any) {
    if (!_.isArray(response)) {
      response = (response as ArrayPayload<unknown>).results;
    }

    if (!(response as []).length) {
      context.isAllOptionsLoaded = true;
    } else {
      const newOptions = this.utilsService.transformObjectArraysToSeletOptions(
        response,
        context.optionsSource.viewNameForTransformToSelects,
        context.optionsSource.keyForTransformToSelects,
      );
      this.initNewOptions(context, newOptions);
    }

    if (context.options.length < context.optionsSource.limit) {
      context.isAllOptionsLoaded = true;
      this.setOptionsContainerHeight(context);
    } else if (!context.optionsContainerHeightPx) {
      this.setOptionsContainerHeight(context);
    }

    this.updateOptionsState(context);
    context.isOptionsLoading = false;
  }

  loadMissingOptionResponseActions(context: SelectComponent, loadedItem: unknown) {
    if (loadedItem) {
      const missingOption = this.utilsService.transformObjectArraysToSeletOptions(
        [loadedItem],
        context.optionsSource.viewNameForTransformToSelects,
        context.optionsSource.keyForTransformToSelects,
      )[0];
      this.setSelectedOption(context, missingOption);
    }
  }

  seletOptionsByUpdatorKey(context: SelectComponent, selectedOptionsValues: string[]) {
    selectedOptionsValues.forEach((selectedOptionValue) => {
      this.seletOptionByUpdatorKey(context, selectedOptionValue);
    });
  }

  setSelectOptionByOption(context: SelectComponent, selectedOption: ISelectOption) {
    const targetOption = context.options?.find((option) => this.isEqualOptions(context, selectedOption, option));
    this.setSelectedOption(context, targetOption);
  }

  setSelectOptionsByOptions(context: SelectComponent, selectedOptions: ISelectOption[]) {
    context.selectedOptions = [];
    selectedOptions.forEach((selectedOption) => {
      this.setSelectOptionByOption(context, selectedOption);
    });
  }

  setSelectedOption(context: SelectComponent, option: ISelectOption) {
    if (option) {
      option.isSelected = true;

      if (this.isSingleSelectModeEnabled(context)) {
        context.selectedOption = option;
      } else if (this.isMultiSelectModeEnabled(context)) {
        context.selectedOptions.push(option);
      }
    }
  }

  isEqualOptions(context: SelectComponent, first: ISelectOption, second: ISelectOption): boolean {
    let keyToCompare: string = context.optionsUpdatorField || SelectUpdatorField.Key;

    if (!first?.key && !context.optionsUpdatorField) {
      if (first.filterGroupValue) {
        keyToCompare = 'filterGroupValue';
      } else {
        keyToCompare = 'filterValue';
      }
    }

    return first[keyToCompare]?.toString() === second[keyToCompare]?.toString();
  }

  handleFilesInput(context: SelectComponent, event: Event, option: ISelectOption) {
    option.files = (event.target as HTMLInputElement).files as unknown as File[];
    option.waitForFileUpload = false;
    context.selectedOptionChangeEvent.emit(option);
    (this.domService.getElementById(option.id) as HTMLInputElement).value = null;
  }

  isIconBarEnabled(context: SelectComponent): boolean {
    return !!context.barIconKey && !!context.barIconHeight && !!context.barIconWidth;
  }

  isMenuModeEnabled(context: SelectComponent): boolean {
    return context.mode === SelectMode.Menu;
  }

  isSingleSelectModeEnabled(context: SelectComponent): boolean {
    return context.mode === SelectMode.SingleSelect;
  }

  isMultiSelectModeEnabled(context: SelectComponent): boolean {
    return context.mode === SelectMode.MultiSelect;
  }

  isDefaultViewMode(context: SelectComponent): boolean {
    return context.viewMode === SelectViewMode.Default;
  }

  isInlineViewMode(context: SelectComponent): boolean {
    return context.viewMode === SelectViewMode.Inline;
  }

  getSelectBarHeight(context: SelectComponent): string {
    return this.isIconBarEnabled(context) ? context.barIconHeight : context.barHeight;
  }

  getSelectBarData(context: SelectComponent): string {
    switch (context.mode) {
      case SelectMode.SingleSelect: {
        return context.selectedOption?.viewName ? context.selectedOption?.viewName : context.placeholder;
      }
      case SelectMode.MultiSelect: {
        return context.selectedOptions?.length
          ? context.selectedOptions?.map((o) => o.viewName).join(', ')
          : context.placeholder;
      }
    }
  }
}
